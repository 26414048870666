import {CustomTicketFieldConfig} from "@biletix/core/models/event/custom-ticket-field-config.model";
import {FieldConfig} from "@biletix/core/models/base/field-config.model";
import {CustomValidators} from "@biletix/core/common/validators/custom-validators";
import {ValidationFieldConfig} from "@biletix/core/models/event/validation-field-config.model";
import {SelectItem} from "@biletix/core/view-models/select-item.model";
import {Gender} from "@biletix/core/common/enums/gender.enum";

export class FactoryUtils {


  static MOUNTHS: SelectItem[] = [
    {value: '01', label: '01'},
    {value: '02', label: '02'},
    {value: '03', label: '03'},
    {value: '04', label: '04'},
    {value: '05', label: '05'},
    {value: '06', label: '06'},
    {value: '07', label: '07'},
    {value: '08', label: '08'},
    {value: '09', label: '09'},
    {value: '10', label: '10'},
    {value: '11', label: '11'},
    {value: '12', label: '12'}
  ];

  public static getYears(start: number, end: number, inc: number = 1): SelectItem[] {
    const years: SelectItem[] = [];
    for (let year = start; year <= end; year += inc) {
      const item: SelectItem = {label: year.toString(), value: year.toString()};
      years.push(item);
    }
    return years;
  }

  /**
   * Gets the time value in milliseconds.
   * @return number
   */
  static tic(): number {
    let currentTime = new Date().getTime();
    return currentTime;
  }

  /**
   *Get elapsed time value
   * @param milliSeconds get current time value
   * @param message optional message
   */
  static toc(milliSeconds: number): number {
    let nowMilliSeconds = new Date().getTime();
    let elapsed = (nowMilliSeconds - milliSeconds);
    return elapsed;
  }


  static toPriceWithDot(value: number | string | any, currencyCode: string = '₺') {
    if (!value || value < 0)
      return currencyCode.concat("0.0") ;

    let decimalValue = (value / 100.0).toFixed(2);
    return currencyCode.concat(decimalValue);
  }

  static toShortTimeText(seconds: number): string {
    let m, s;
    m = Math.floor(seconds / 60);
    s = seconds - m * 60;
    return this.witMergeZero(m) + ":" + this.witMergeZero(s);
  }

  static formatAmount(amount:number): String{
    return Math.floor(amount/100)+","+amount%100;
  }

  static formatAmountDataLayer(amount:number): String{
    return Math.floor(amount/100)+"."+amount%100;
  }

  static capitalizeFirstCharacter(str: string): string {
    if (str.length === 0) {
      return str; // Empty string, return as is
    }
    
    const firstChar = str.charAt(0).toUpperCase(); // Get the first character and capitalize it
    const remainingChars = str.slice(1); // Get the remaining characters
    
    return firstChar + remainingChars;
  }

  static calculateRemainingDays(targetDate: Date): number {
    const currentDate = new Date();
    targetDate = new Date(targetDate);
    const totalMilliseconds = targetDate.getTime() - currentDate.getTime();
    const totalDays = Math.ceil(totalMilliseconds / (1000 * 60 * 60 * 24));
  
    return totalDays;
  }

  private static witMergeZero(n: number): string {
    let text = "";
    if (n && n >= 10) {
      text = n + "";
    } else {
      text = "0" + n;
    }
    return text;
  }

  static fieldsToFieldConfigs(customFields: CustomTicketFieldConfig[] | ValidationFieldConfig[] | any): FieldConfig[] {
    let fieldConfigs: FieldConfig[] = [];
    if (customFields && customFields.length > 0)
      fieldConfigs = customFields?.map((cf: CustomTicketFieldConfig,i:number) => this.fieldToFieldConfig(cf,i));
    return fieldConfigs;
  }

  private static fieldToFieldConfig(customField: CustomTicketFieldConfig | ValidationFieldConfig,i:any): FieldConfig {
    const fieldConfig: FieldConfig = <FieldConfig>customField;
    fieldConfig.name = customField.key;
    fieldConfig.validations = CustomValidators.createValidations(customField);
    return fieldConfig;
  }

  static clone<S>(src: S): any {
    return JSON.parse(JSON.stringify(src));
  }


  static getGenders():SelectItem[] {
    return [
      {
        label: "shared.labels.female",
        value: Gender.FEMALE
      },
      {
        label: "shared.labels.male",
        value: Gender.MALE
      }
    ]
  }
}
