export enum Gender {

  //Bilinmeyen
  UNKNOWN = "UNKNOWN",

  //ERKEK
  MALE = "MALE",

  //KADIN
  FEMALE = "FEMALE"


}
